<template>
  <div class="wiki-wrapper">
    <div class="wiki-content__wrap">
      <img width="320" :src="$isDark() ? require('@/assets/img/help/wiki-bg.png') : require('@/assets/img/help/wiki-bg-light.png')" alt="" />
      <p>{{$t('wiki.dev')}}</p>
    </div>
  </div>
</template>

<script setup>

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
.wiki-wrapper {
  padding: 0 24px;
  height: calc(100% - 60px);
  display: flex;
  font-size: 24px;
  justify-content: center;
  color: var(--color-text-2);
  align-items: center;
  text-align: center;
}
</style>
